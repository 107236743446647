<template>
    <div class="card card-body shadow-sm app-bigcategory">
        <app-basic-table
            ref="basic_table"
            :table-name="$t('users.user list')"
            :filters.sync="filters"
            :setting-columns="columns"
            :endpoint-list="ENDPOINT.BIG_CATEGORY_LIST"
        >
            <template v-slot:table-menu-right>
                <button @click="handleBtnCreateClick()" class="btn btn-warning">
                    {{ $t("common.create") }}
                </button> 
            </template>
            
            <template v-slot:body-cell-edit="props">
                <td class="app-align-middle text-center app-p-none">
                <button
                    @click="handleBtnUpdateClick(props.row)"
                    class="btn btn-primary"
                >
                    {{ $t("common.edit") }}
                </button>
                </td>
            </template>
        </app-basic-table>
    </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ROLES, BASIC_TABLE} from "@constants";
export default {
    name: "BigCategoryList",

    components: {
        "app-basic-table": AppBasicTable
    },

    data() {
        return {
            idCache: null,
            paramGetList: {},
            entry: {

            },
            filters: {
                
            },
            columns: [
                {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
                {name: "name", label: this.$t("big-category.name"), sortable: true},
                {name: "note", label: this.$t("big-category.note")},
                {name: "edit", label: this.$t("common.edit")},
            ],
            meta: {
                
            }
        }
    },

    methods: {
        handleBtnCreateClick() {
            this.$router.push({name: this.ROUTES.ADMIN.BIG_CATEGORY_CREATE})
        },

        handleBtnUpdateClick(entry) {
            this.$router.push({name: this.ROUTES.ADMIN.BIG_CATEGORY_EDIT, params: {id: entry.id}})
        },
    },
}
</script>
<style>
    .app-bigcategory .app-table-container .table th:nth-child(1) {
        width: 3.4%;
    }
    .app-bigcategory .app-table-container .table th:nth-child(2) {
        width: 27%;
    }
    .app-bigcategory .app-table-container .table th:nth-child(3) {
        width: 54.6%;
    }
    .app-bigcategory .app-table-container .table th:nth-child(4) {
        width: 15%;
    }
    @media (max-width: 1500px) {
        .app-bigcategory .app-table-container .table th:nth-child(3) {
            width: 49.6%;
        }
        .app-bigcategory .app-table-container .table th:nth-child(4) {
            width: 20%;
        }
    }
</style>