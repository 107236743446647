import { render, staticRenderFns } from "./BigCategoryList.vue?vue&type=template&id=fb0ad65c&"
import script from "./BigCategoryList.vue?vue&type=script&lang=js&"
export * from "./BigCategoryList.vue?vue&type=script&lang=js&"
import style0 from "./BigCategoryList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports